import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";

export default {
  components: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Group" },
      {
        title: "Navigation Menu Group",
        route: "/group/navigation-menu"
      },
      { title: "Blacklisted Resources" }
    ]);
  }
};
